/** @format */

export const DurationConst = {
    MONTH_1: '1-MONTH',
    MONTH_6: '6-MONTH',
    MONTH_12: '12-MONTH',
    CHARTS_12: '12-CHARTS',
};

const BasePrices = {
    [DurationConst.MONTH_1]: 299,
    [DurationConst.MONTH_6]: 1499,
    [DurationConst.MONTH_12]: 2399,
    [DurationConst.CHARTS_12]: 999,
};

const DiscountPrices = {
    [DurationConst.MONTH_1]: 299,
    [DurationConst.MONTH_6]: 999,
    [DurationConst.MONTH_12]: 1499,
    [DurationConst.CHARTS_12]: 999,
};

/* чтобы 3 января автоматически цены стали другие */
const today = new Date();
const isSpecialDate = today.getMonth() === 0 && today.getDate() === 3;

export const AmountConst = isSpecialDate ? DiscountPrices : BasePrices;

export const DurationTitleConst = {
    [DurationConst.MONTH_1]: '1 месяц Pro',
    [DurationConst.MONTH_6]: '6 месяцев Pro',
    [DurationConst.MONTH_12]: '1 год Pro',
    [DurationConst.CHARTS_12]: '1 год',
};

export const MonthlyPriceTitleConst = {
    [DurationConst.MONTH_1]: 'в месяц',
    [DurationConst.MONTH_6]: 'за полгода',
    [DurationConst.MONTH_12]: 'в год',
};

export const MonthlyPriceConst = {
    [DurationConst.MONTH_1]: AmountConst[DurationConst.MONTH_1],
    [DurationConst.MONTH_6]: Math.floor(AmountConst[DurationConst.MONTH_6] / 6),
    [DurationConst.MONTH_12]: Math.floor(
        AmountConst[DurationConst.MONTH_12] / 12
    ),
    [DurationConst.CHARTS_12]: Math.floor(
        AmountConst[DurationConst.CHARTS_12] / 12
    ),
};
