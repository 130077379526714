/** @format */

import { useEffect } from 'react';

export default function DownloadSimAppPro() {
    useEffect(() => {
        window.location.href =
            'https://download.wwsimstore.com/download/SimAppPro/SimAppPro%20Setup%201.16.19.exe';
    }, []);

    return null;
}
